var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container ClassManagement" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { height: "40px", margin: "0px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-right": "0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          width: "18.6%",
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("呼叫类型："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请选择呼叫类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.screen.callType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.screen, "callType", $$v)
                                  },
                                  expression: "screen.callType",
                                },
                              },
                              _vm._l(_vm.call_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          width: "18.6%",
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("接听状态："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请选择接听状态",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.screen.answerStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.screen, "answerStatus", $$v)
                                  },
                                  expression: "screen.answerStatus",
                                },
                              },
                              _vm._l(_vm.answer_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          width: "18.6%",
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("通话时长："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请选择通话时长",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.screen.talkTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.screen, "talkTime", $$v)
                                  },
                                  expression: "screen.talkTime",
                                },
                              },
                              _vm._l(_vm.talk_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          width: "22.6%",
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 9, lg: 9, xl: 6 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("坐席所属部门："),
                            ]),
                            _vm._v(" "),
                            _c("el-cascader", {
                              attrs: {
                                size: "small",
                                clearable: "",
                                options: _vm.bumenoptions,
                                placeholder: "请选择所属部门",
                                props: { value: "id", label: "name" },
                              },
                              on: {
                                change: _vm.handleChange,
                                focus: _vm.DumenFocus,
                              },
                              model: {
                                value: _vm.screen.bumenoptionsName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.screen, "bumenoptionsName", $$v)
                                },
                                expression: "screen.bumenoptionsName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          width: "18.6%",
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 9, lg: 9, xl: 6 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户来源："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "51%" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  clearable: "",
                                  size: "small",
                                  "reserve-keyword": "",
                                  placeholder: "请搜索花名",
                                  "remote-method": _vm.flowerArrayEs,
                                },
                                on: { focus: _vm.FlowerArrayFocus },
                                model: {
                                  value: _vm.screen.flowerNameId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.screen, "flowerNameId", $$v)
                                  },
                                  expression: "screen.flowerNameId",
                                },
                              },
                              _vm._l(_vm.flower_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.flower_name,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户级别："),
                            ]),
                            _vm._v(" "),
                            _c("el-cascader", {
                              attrs: {
                                size: "small",
                                clearable: "",
                                options: _vm.GetFieldInfodata2,
                                placeholder: "请选择客户级别",
                                props: { label: "name", value: "id" },
                              },
                              on: {
                                focus: _vm.PostMultipleFocus,
                                change: _vm.handleChangeLevel,
                              },
                              model: {
                                value: _vm.screen.Batchstudentlevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.screen, "Batchstudentlevel", $$v)
                                },
                                expression: "screen.Batchstudentlevel",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 12, lg: 12, xl: 8 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "source",
                            staticStyle: { "padding-top": "6px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("呼叫开始时间："),
                            ]),
                            _vm._v(" "),
                            _c("date-picker", {
                              attrs: { start: _vm.screen.start },
                              on: { FinalPicker: _vm.FinalPicker },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 5, lg: 5, xl: 4 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "source",
                            staticStyle: { "padding-top": "6px" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "客户名称、电话搜索",
                              },
                              model: {
                                value: _vm.screen.cname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.screen, "cname", $$v)
                                },
                                expression: "screen.cname",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 5, lg: 5, xl: 4 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "source",
                            staticStyle: { "padding-top": "6px" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "坐席名称搜索",
                              },
                              model: {
                                value: _vm.screen.cno,
                                callback: function ($$v) {
                                  _vm.$set(_vm.screen, "cno", $$v)
                                },
                                expression: "screen.cno",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "source",
                            staticStyle: { "padding-top": "6px" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.queryClick },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        id: "customerTable",
                        "header-cell-style": {
                          background: "#F8F9FB",
                          color: "#222222",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        key: 1,
                        attrs: { label: "序号", align: "center", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3946342523
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mobile",
                          label: "客户电话",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cname",
                          label: "客户名称",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.cname
                                    ? _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890ff" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goTodetails(
                                                scope.row,
                                                scope
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.cname
                                                  ? scope.row.cname
                                                  : "--"
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _c("span", [_vm._v("--")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1397251265
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cno_name",
                          label: "坐席姓名",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cno",
                          label: "坐席工号",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "type",
                          label: "呼叫类型",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type == 1
                                    ? _c("span", [_vm._v("系统外呼")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.type == 2
                                    ? _c("span", [_vm._v("手机外呼")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.type == 3
                                    ? _c("span", [_vm._v("系统呼入")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.type == 4
                                    ? _c("span", [_vm._v("手机呼入")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2060738708
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "status",
                          label: "接听状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 1
                                    ? _c("span", [_vm._v("客户未接听")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c("span", [_vm._v("双方接听")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 3
                                    ? _c("span", [_vm._v("坐席未接听")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1812947812
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "talkDuration",
                          label: "通话时长",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.talkDuration
                                          ? scope.row.talkDuration
                                          : "--"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          892026531
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cname",
                          label: "通话录音",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.has_audio == 1
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            "justify-content": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: ["播放"],
                                                  expression: "['播放']",
                                                },
                                              ],
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "播放",
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-yunhang",
                                                staticStyle: {
                                                  "font-size": "22px",
                                                  "margin-right": "10px",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewAudio(
                                                      scope.row.cst_id,
                                                      1
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: ["下载"],
                                                  expression: "['下载']",
                                                },
                                              ],
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "下载",
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "iconfont icon-iconfontzhizuobiaozhun023146",
                                                staticStyle: {
                                                  "font-size": "18px",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewAudio(
                                                      scope.row.cst_id,
                                                      2,
                                                      scope.row.type
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("--")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3819130862
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sname",
                          label: "坐席所属部门",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "startTime",
                          label: "呼叫开始时间",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bridgeTime",
                          label: "接通时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.bridgeTime
                                          ? scope.row.bridgeTime
                                          : "--"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1504069219
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "customer_level_name",
                          label: "学员级别",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "flower_name",
                          label: "客户来源",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "通话录音",
                  visible: _vm.dialogVisible,
                  width: "30%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                  close: _vm.close,
                },
              },
              [
                _vm.amrUrl
                  ? _c("div", { staticClass: "myaudio" }, [
                      _vm.playBtn
                        ? _c("span", {
                            staticClass: "iconfont icon-bofangqi-bofang",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.openRecording()
                              },
                            },
                          })
                        : _c("span", {
                            staticClass: "iconfont icon-zantingtingzhi",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.openRecording()
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "amr-progress" } }, [
                        _c("span", { attrs: { id: "amr-cur" } }, [
                          _vm._v(_vm._s(_vm.cur)),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("/")]),
                        _vm._v(" "),
                        _c("span", { attrs: { id: "amr-duration" } }, [
                          _vm._v(_vm._s(_vm.duration)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticStyle: { width: "50%" },
                        attrs: {
                          id: "amr-progress",
                          type: "range",
                          min: "0",
                          max: _vm.progressCtrl,
                          step: "any",
                        },
                        domProps: { value: _vm.progressValue },
                        on: {
                          change: _vm.progressChange,
                          mouseup: _vm.progressUp,
                          mousedown: _vm.progressDown,
                        },
                      }),
                    ])
                  : _c("audio", {
                      attrs: {
                        id: "audio",
                        width: "100%",
                        src: _vm.audioUrl,
                        controls: "",
                      },
                    }),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }