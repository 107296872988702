import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import { agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import { getTokens } from '@/utils/auth';
import moment from 'moment';
import TimeComponent from '@/components/TimeComponent/index';
import { getStructureListLow } from '@/api/PublicAPI';
import { getCustomerLevel, callCenterList, getAudioUrl } from '@/api/ceshi_xiugai/whole';
import Head from '@/components/head/index';
import DatePicker from '@/components/DatePicker/index';
import BenzAMRRecorder from 'benz-amr-recorder';
export default {
  name: 'ClassManagement',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      menuId: localStorage.getItem('menuId'),
      // 系统教程
      title: '呼叫中心',
      dialogVisible: false,
      audioUrl: '',
      amrUrl: false,
      amr: null,
      //播放对象
      playBtn: true,
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      call_Array: [{
        id: 0,
        name: '全部'
      }, {
        id: 1,
        name: '系统外呼'
      }, {
        id: 2,
        name: '手机外呼'
      }, {
        id: 3,
        name: '系统呼入'
      }, {
        id: 4,
        name: '手机呼入'
      }],
      answer_Array: [{
        id: 0,
        name: '全部'
      }, {
        id: 1,
        name: '客户未接听'
      }, {
        id: 3,
        name: '坐席未接听'
      }, {
        id: 2,
        name: '双方接听'
      }],
      talk_Array: [{
        id: 0,
        name: '全部'
      }, {
        id: 1,
        name: '0-30s'
      }, {
        id: 2,
        name: '30-60s'
      }, {
        id: 3,
        name: '60-100s'
      }, {
        id: 4,
        name: '100-200s'
      }, {
        id: 5,
        name: '200-300s'
      }, {
        id: 6,
        name: '300s以上'
      }],
      listLoading: true,
      tableData: [],
      flower_Array: [],
      //客户来源
      bumenoptions: [],
      GetFieldInfodata2: [],
      tempId: '',
      bumenoptionsId: '',
      screen: {
        callType: '',
        answerStatus: '',
        talkTime: '',
        start: [],
        create_stime: '',
        create_etime: '',
        cname: '',
        cno: '',
        flowerNameId: '',
        Batchstudentlevel: '',
        bumenoptionsName: '',
        bumenoptionsId: '',
        customerLevel: ''
      },
      progressCtrl: 1,
      duration: 0,
      cur: '00:00',
      isDragging: false,
      ReckonTime: '',
      progressValue: 0
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.screening();
    } else {}
  },
  components: {
    Head: Head,
    DatePicker: DatePicker,
    TimeComponent: TimeComponent
  },
  methods: {
    PostMultipleFocus: function PostMultipleFocus() {
      var _this = this;
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        _this.GetFieldInfodata2 = res.data;
      });
    },
    FlowerArrayFocus: function FlowerArrayFocus() {
      var _this2 = this;
      agentFlowerNameList().then(function (res) {
        _this2.flower_Array = res.data;
      });
    },
    flowerArrayEs: function flowerArrayEs(val) {
      var _this3 = this;
      //改变花名
      agentFlowerNameList({
        key_word: val
      }).then(function (res) {
        _this3.flower_Array = res.data;
      });
    },
    FinalPicker: function FinalPicker(val) {
      this.screen.create_stime = val[0];
      this.screen.create_etime = val[1];
    },
    //时间
    screening: function screening() {
      var startTime = moment().startOf('day');
      var start = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
      var end = new Date();
      this.screen.start.unshift(start);
      this.screen.start.push(end);
      start = Date.parse(start).toString().substring(0, 10);
      end = Date.parse(end).toString().substring(0, 10);
      this.screen.create_stime = start;
      this.screen.create_etime = end;
      this.getList(this.query.page, this.query.pagesize);
    },
    getList: function getList(page, pagesize) {
      var _this4 = this;
      this.listLoading = true;
      var then = this.screen;
      callCenterList({
        page: page,
        pagesize: pagesize,
        s_call_type: then.callType,
        s_status: then.answerStatus,
        s_duration: then.talkTime,
        s_stime: then.create_stime,
        s_etime: then.create_etime,
        s_structure_id: then.bumenoptionsId.toString(),
        s_customer_level: then.customerLevel.toString(),
        s_cname_phone: then.cname,
        s_cno_name: then.cno,
        s_source_client_id: then.flowerNameId
      }).then(function (respomse) {
        _this4.tableData = respomse.data.data;
        _this4.query.total = respomse.data.total;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.tableData = [];
        _this4.listLoading = false;
      });
    },
    handleChange: function handleChange(val) {
      //跟进人所属部门
      var share = [val].map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.screen.bumenoptionsId = share;
    },
    handleChangeLevel: function handleChangeLevel(val) {
      //跟进人所属部门
      var level = [val].map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.screen.customerLevel = level;
    },
    DumenFocus: function DumenFocus() {
      var _this5 = this;
      getStructureListLow({
        type: '0',
        get_type: '0'
      }).then(function (respomse) {
        _this5.bumenoptions = respomse.data;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    goTodetails: function goTodetails(row, val) {
      //客户详情

      var routeUrl = this.$router.resolve({
        path: '/receiveDetail?Cid=' + row.cid + '&CData_id=' + row.cdid
      });
      var asas = JSON.stringify(this.resultES);
      localStorage.setItem('resultES', asas);
      window.open(routeUrl.href, '_blank');
    },
    queryClick: function queryClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    downloadExcel: function downloadExcel(res, fileName, type) {
      var a = document.createElement('a');
      if (type == 1) {
        var blob = new Blob([res.data], {
          type: 'audio/mpeg'
        });
        var url = URL.createObjectURL(blob);
        a.setAttribute('href', url);
        a.setAttribute('download', fileName);
        a.click();
      } else {
        var _blob = new Blob([res.data], {
          type: 'audio/amr'
        });
        var _url = URL.createObjectURL(_blob);
        a.setAttribute('href', _url);
        a.setAttribute('download', fileName);
        a.click();
      }
    },
    //播放语音
    openRecording: function openRecording(url) {
      var _this6 = this;
      this.amr.playOrPauseOrResume();
      if (this.amr.isPlaying()) {
        this.playBtn = false;
        this.intervalFun();
      } else {
        this.playBtn = true;
        clearInterval(this.ReckonTime);
        return;
      }
      this.amr.onEnded(function () {
        _this6.playBtn = true;
      });
    },
    intervalFun: function intervalFun() {
      var _this7 = this;
      this.ReckonTime = setInterval(function () {
        if (_this7.amr) {
          _this7.cur = _this7.formatSeconds(_this7.amr.getCurrentPosition());
          if (!_this7.isDragging) {
            _this7.progressValue = _this7.amr.getCurrentPosition().toFixed(2);
          }
        } else {
          _this7.cur = '00:00';
        }
      }, 10);
    },
    //停止播放
    stopPlayVoice: function stopPlayVoice() {
      this.amr.stop();
      this.playBtn = true;
    },
    progressChange: function progressChange(e) {
      this.amr.setPosition(e.target.value);
    },
    progressUp: function progressUp() {
      this.isDragging = false;
    },
    progressDown: function progressDown() {
      this.isDragging = true;
    },
    formatSeconds: function formatSeconds(result) {
      var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      var m = Math.floor(result / 60 % 60) < 10 ? '0' + Math.floor(result / 60 % 60) : Math.floor(result / 60 % 60);
      var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
      if (h == '00') {
        return result = m + ':' + s;
      } else {
        return result = h + ':' + m + ':' + s;
      }
    },
    viewAudio: function viewAudio(id, flag, type) {
      var _this8 = this;
      var data = {
        cst_id: id,
        flag: flag
      };
      if (flag == 1) {
        this.dialogVisible = true;
        getAudioUrl(data).then(function (res) {
          _this8.audioUrl = res.data.audio_url;
          if (res.data.audio_url.indexOf('amr') != -1) {
            _this8.amrUrl = true;
            _this8.amr = new BenzAMRRecorder(); //建立
            //⚠️注意跨域问题
            _this8.amr.initWithUrl(_this8.audioUrl) //初始化
            .then(function () {
              _this8.progressCtrl = _this8.amr.getDuration().toFixed(2);
              _this8.duration = _this8.formatSeconds(_this8.amr.getDuration());
            }).catch(function (e) {
              _this8.playBtn = true;
              _this8.$message.error('播放录音失败');
            });
          } else {
            _this8.amrUrl = false;
          }
        });
      } else {
        axios.defaults.headers.common['token'] = getTokens();
        axios.defaults.headers.common['content-type'] = 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        axios.post(process.env.VUE_APP_BASE_API2 + '/clientserver.php/CallMaster/getAudioUrl', data, {
          responseType: 'blob'
        }).then(function (res) {
          _this8.downloadExcel(res, '通话录音', type);
        });
      }
    },
    close: function close() {
      if (this.amrUrl) {
        this.stopPlayVoice();
        this.progressValue = 0;
        this.cur = '00:00';
      } else {
        var audio = document.querySelector('#audio');
        audio.pause();
      }
    }
  }
};